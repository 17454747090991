import $f, { jwt } from "../components/lib";

export const calculatePrice = async (payload) => {
  if (payload.method === "UPLOAD_FILE") {
    const form = new FormData();
    Object.keys(payload).map((item) => {
      form.append(item, payload[item]);
    });

    return $f
      .httpApiCall({
        url: `/api/calculate-price/${payload.projectId}/file`,
        method: "POST",
        headers: {
          authorization: jwt(),
        },
        body: form,
      })
      .then((res) => res);
  } else {
    return $f
      .httpApiCall({
        url: "/api/calculate-price",
        method: "POST",
        body: JSON.stringify(payload),
      })
      .then((res) => res);
  }
};
