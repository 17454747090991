import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import DeleteProject from "./projects/deleteProject";
import GenerateScript from "./projects/generateScript";
import Ingest from "./projects/ingest";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import ReactDOM from "react-dom";
import { AlertList } from "react-bs-notifier";
import GoogleReindex from "./pages/googleReindex";
import ImportPages from "./projects/importPages";
import base64 from "base-64";
import UploadGoogleJSONFile from "./pages/googleIndex";
import Button from "./common/button";
import { routes } from "../routes";
import { matchPath } from "react-router-dom/cjs/react-router-dom.min";

export const jwt = () => {
  return "Bearer ".concat(localStorage.getItem("jwt"));
};
const $f = {
  baseURL: (url) => {
    return "https://api.12monkeys.io".concat(url);
    // return "http://213.136.81.243:8080".concat(url);
  },
  fetch: async (url, method, callback, postData) => {
    return fetch($f.baseURL(url), {
      method: method ? method : "GET",
      headers: {
        Accept: "application/json",
        authorization: jwt(),
        "Content-Type": "application/json",
      },
      body: postData,
    })
      .then((response) => {
        if (response.status == 401) {
          $f.logout(window.location.href);
        }

        return response.json();
      })
      .then((data, resp) => {
        return callback(data);
      });
  },
  httpApiCall: async ({
    url,
    method,
    headers,
    body,
    responseType = "json",
    rawResponse = false,
  }) => {
    return fetch($f.baseURL(url), {
      method: method ? method : "GET",
      headers: headers
        ? headers
        : {
            Accept: "application/json",
            authorization: jwt(),
            "Content-Type": "application/json",
          },
      body: method !== "GET" ? body : undefined,
    })
      .then(async (response) => {
        if (!response.ok) {
          const errorResponse = await response.json();
          if (response.status === 401) {
            $f.logout(window.location.href);
          } else if (errorResponse?.redirectURL) {
            window.location.replace(errorResponse.redirectURL);
          } else {
            throw await errorResponse;
          }
        } else {
          return response;
        }
      })
      .then((response) => {
        if (rawResponse) {
          return response;
        }
        if (responseType === "blob") {
          return response?.blob();
        }

        return response?.json();
      });
  },
  logout: (url) => {
    localStorage.clear();
    if (url) {
      if (typeof url === "string") {
        window.location.replace("/login?redirect=" + url);
      } else {
        window.location.replace("/login");
      }
    } else {
      window.location.replace("/login");
    }
  },
  notifications: [],
  createNotification(args) {
    if (args.notification) {
      const idx = $f.notifications.indexOf(args.notification);

      if (idx >= 0) {
        $f.notifications = [
          ...$f.notifications.slice(0, idx),
          ...$f.notifications.slice(idx + 1),
        ];
      }
    } else {
      $f.notifications.push({
        id: new Date().getTime(),
        type: args.type,
        message: <span className="pr-2">{args.message}</span>,
      });
    }
    ReactDOM.render(
      <AlertList
        alerts={$f.notifications}
        timeout={5000}
        onDismiss={(item) => {
          $f.createNotification({ notification: item });
        }}
      />,
      document.getElementById("notification")
    );
  },
  downloadFile({ response, title, extension }) {
    try {
      const url = window.URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${title}.${extension}`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {}
  },
  removeTrailingSlash(url) {
    return url?.replace(/\/$/, "") ?? "";
  },
  capitalizeFirstLetter(s) {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  },
  getInitials({ name, surname }) {
    if (name && surname) {
      return name.charAt(0) + surname.charAt(0);
    } else {
      return "";
    }
  },
  createTabs(tabs, actions) {
    return (
      <>
        <nav className="d-flex justify-content-between">
          <div
            className="nav nav-tabs"
            style={{ border: "none" }}
            id="nav-tab"
            role="tablist"
          >
            {tabs.map(
              (item, index) =>
                item.canIsee && (
                  <button
                    key={item.id}
                    className={`nav-link ${item.isActive ? "active" : ""}`}
                    id={item.id}
                    data-bs-toggle="tab"
                    data-bs-target={item.target}
                    type="button"
                    role="tab"
                    aria-controls={item.controls}
                    aria-selected="true"
                    onClick={() => {
                      window.history.pushState("", "", item.target);
                    }}
                  >
                    {item.icon}
                    {item.tab}
                  </button>
                )
            )}
          </div>
          <div>
            {actions?.map((item, index) => {
              return <Button key={index} {...item} />;
            })}
          </div>
        </nav>
        <hr style={{ margin: 0 }}></hr>
        <div className="tab-content" id="nav-tabContent">
          {tabs.map(
            (item, index) =>
              item.canIsee && (
                <div
                  key={item.id}
                  className={`tab-pane mt-2 fade show ${
                    item.isActive ? "active" : ""
                  }`}
                  id={item.controls}
                  role="tabpanel"
                  aria-labelledby={item.id}
                  // tabindex={index}
                >
                  {item.content}
                </div>
              )
          )}
        </div>
      </>
    );
  },
  createTable(headers, data) {
    const _tableHeaders = [...headers];
    const _tableData = [...data];

    return (
      <PerfectScrollbar>
        <table className="table table-hover">
          <thead>
            {_tableHeaders?.map((el) => {
              return (
                <th key={el.id} {...el.th}>
                  {el.label}
                </th>
              );
            })}
          </thead>
          <tbody>
            {_tableData?.map((row) => {
              return (
                <tr key={$f.uuid()}>
                  {_tableHeaders?.map((cell) => {
                    return (
                      <td key={cell.id} {...cell.td}>
                        {cell.formatValue
                          ? cell.formatValue(row)
                          : row[cell.id]}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </PerfectScrollbar>
    );
  },
  uuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },
  chunkArray(array, size) {
    const chunk = function (array, size) {
      if (!array.length) {
        return [];
      }
      const head = array.slice(0, size);
      const tail = array.slice(size);

      return [head, ...chunk(tail, size)];
    };
    return chunk(array, size);
  },
  getUserProperty(property) {
    try {
      const info = JSON.parse(base64.decode(localStorage.getItem("info")));
      return info[property];
    } catch (e) {}
    return null;
  },
  formatDate(input, args) {
    const { format } = args ?? {};
    if (format) {
      return moment(input).format(format);
    } else {
      return moment(input).format("ddd DD MMM YY, HH:mm");
    }
  },
  isObjectEmpty(obj) {
    return Object.keys(obj).length === 0;
  },
  exportJSON(projectId, title) {
    fetch($f.baseURL("/api/project/jsonFile?projectId=".concat(projectId)), {
      method: "GET",
      headers: {
        authorization: jwt(),
      },
    })
      .then((response) => response.blob())
      .then((resp) => {
        try {
          const url = window.URL.createObjectURL(resp);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", title);
          document.body.appendChild(link);
          link.click();
        } catch (e) {}
      });
  },
  exportPages(projectId, title) {
    fetch($f.baseURL("/api/export/excel/".concat(projectId)), {
      method: "GET",
      headers: {
        authorization: jwt(),
      },
    })
      .then((response) => response.blob())
      .then((resp) => {
        try {
          const url = window.URL.createObjectURL(resp);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", title);
          document.body.appendChild(link);
          link.click();
        } catch (e) {}
      });
  },
  downloadSitemap(projectId) {
    fetch($f.baseURL("/api/project/sitemap?projectId=".concat(projectId)), {
      method: "GET",
      headers: {
        authorization: jwt(),
      },
    })
      .then((response) => response.blob())
      .then((resp) => {
        try {
          const url = window.URL.createObjectURL(resp);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "sitemap-".concat(projectId).concat(".json")
          );
          document.body.appendChild(link);
          link.click();
        } catch (e) {}
      });
  },
  importPages(file) {
    let postData = new FormData();
    postData.set("file ", file[0]);
    fetch($f.baseURL("/api/import/excel"), {
      method: "POST",
      headers: {
        authorization: jwt(),
      },
      body: postData,
    })
      .then((response) => {
        if (response.status == 200) {
          window["modal"].setState({ show: false });
        }
        return response.json();
      })
      .then((resp) => {});
  },
  uploadGoogleJSON(file, projectId) {
    let postData = new FormData();

    postData.set("projectId", projectId);
    postData.set("image ", file[0]);

    fetch($f.baseURL("/api/project/jsonFile"), {
      method: "PUT",
      headers: {
        authorization: jwt(),
      },
      body: postData,
    })
      .then((response) => {
        if (response.status == 200) {
          window["modal"].setState({ show: false });
        }
        return response.json();
      })
      .then((resp) => {});
  },
  isSuperAdmin() {
    return JSON.parse(base64.decode(localStorage.getItem("info")))["admin"];
  },

  projectsPanel() {
    const actitve = (id) => {
      if (window.location.pathname.includes(id)) {
        return "text-primary bold";
      } else {
        return "";
      }
    };
    const _classname = "nav-item d-block action-links mb-3 ";

    return (
      <ul className="nav flex-column mt-3">
        <li
          className={_classname.concat(
            window.location.pathname === "/" ? "text-primary bold" : ""
          )}
          role="button"
          data-bs-dismiss="offcanvas"
          data-bs-target="#offcanvasExample"
        >
          <Link to={"/"}>Home</Link>
        </li>
        <li
          data-bs-dismiss="offcanvas"
          data-bs-target="#offcanvasExample"
          className={_classname.concat(actitve("site"))}
          role="button"
        >
          <Link to={"/sites"}>Sites</Link>
        </li>
        {/* <li className={_classname.concat(actitve("billing"))} role="button">
          <Link to={"/billing"}>Billing</Link>
        </li>
        <li className={_classname.concat(actitve("invoice"))} role="button">
          <Link to={"/invoices"}>Invoices</Link>
        </li> */}
        {$f.isSuperAdmin() && (
          <li className={_classname.concat(actitve("client"))} role="button">
            <Link to="/clients">Clients</Link>
          </li>
        )}
      </ul>
    );
  },
  pagePanel(project, page) {
    return (
      <ul className="nav flex-column mt-3">
        <li
          className="nav-item d-block action-links mb-3 small dropdown-item"
          role="button"
        >
          <a
            href={$f.baseURL(
              "/public/utils/updatedUID?projectuid="
                .concat(project.projectId)
                .concat("&pageURL=")
                .concat(page.pageURL)
            )}
            target="_blank"
          >
            <i className="fas fa-external-link-alt fa-fw me-2 "></i>Open updated
            page
          </a>
        </li>
        {project?.projectUserRole !== "VIEWER" && (
          <li
            className="nav-item d-block action-links mb-3 small dropdown-item"
            role="button"
            onClick={() => {
              window["modal"].setState({
                title: "Google reindex",
                size: "default",
                show: true,
                body: <GoogleReindex></GoogleReindex>,
              });
            }}
          >
            <i className="fab fa-google fa-fw me-2 "></i>Google re-index
          </li>
        )}
        {$f.isSuperAdmin() && (
          <li
            className="nav-item d-block action-links mb-3 text-danger small dropdown-item"
            role="button"
            onClick={() => {
              window["modal"].setState({
                title: "Delete page",
                size: "default",
                show: true,
                body: "",
              });
            }}
          >
            <i className="fas fa-trash-alt fa-fw me-2"></i>Delete page
          </li>
        )}
      </ul>
    );
  },
  projectPanel(project) {
    return (
      <ul className="nav flex-column mt-3">
        <li
          className="nav-item d-block action-links mb-3 small dropdown-item"
          role="button"
          onClick={() => {
            window["modal"].setState({
              title: "Project scripts",
              size: "lg",
              show: true,
              body: <GenerateScript></GenerateScript>,
            });
          }}
        >
          <i className="fas fa-scroll fa-fw  me-2"></i>Generate script
        </li>
        <li
          className="nav-item d-block action-links mb-3 small dropdown-item"
          role="button"
          onClick={() => {
            window["modal"].setState({
              title: "Google index file",
              size: "default",
              show: true,
              body: <UploadGoogleJSONFile></UploadGoogleJSONFile>,
            });
          }}
        >
          <i className="fab fa-google fa-fw me-2"></i>Upload Google index file
        </li>
        <li
          className="nav-item d-block action-links mb-3 small dropdown-item"
          role="button"
          onClick={() => {
            $f.exportJSON(project.projectId, project.projectId.concat(".json"));
          }}
        >
          <i className="fas fa-file-code fa-fw me-2"></i>Download Google Json
        </li>
        {$f.isSuperAdmin() && (
          <li
            className="nav-item d-block action-links mb-3 small dropdown-item"
            role="button"
            onClick={() => {
              window["modal"].setState({
                title: "Ingest project",
                size: "default",
                show: true,
                body: <Ingest></Ingest>,
              });
            }}
          >
            <i className="fas fa-bolt fa-fw me-2"></i>Ingest
          </li>
        )}
        <li
          className="nav-item d-block action-links mb-3 small dropdown-item"
          role="button"
          onClick={() => {
            $f.exportPages(project.projectId, project.projectId.concat(".csv"));
          }}
        >
          <i className="far fa-file-excel fa-fw  me-2"></i>Export project pages
        </li>
        <li
          className="nav-item d-block action-links mb-3 small dropdown-item"
          role="button"
          onClick={() => {
            window["modal"].setState({
              title: "Import pages",
              size: "default",
              show: true,
              body: <ImportPages></ImportPages>,
            });
          }}
        >
          <i className="fas fa-file-import fa-fw  me-2"></i>Import pages
        </li>
        <li
          className="nav-item d-block action-links mb-3 small dropdown-item"
          role="button"
          onClick={() => {
            // $f.downloadSitemap(project.projectId);
          }}
        >
          <i className="fas fa-sitemap fa-fw me-2"></i>Download sitemap
        </li>
        {$f.isSuperAdmin() && (
          <li
            className="nav-item d-block action-links mb-3 text-danger small dropdown-item"
            role="button"
            onClick={() => {
              window["modal"].setState({
                title: "Delete project",
                size: "default",
                show: true,
                body: (
                  <DeleteProject projectId={project.projectId}></DeleteProject>
                ),
              });
            }}
          >
            <i className="fas fa-trash-alt fa-fw me-2"></i>Delete project
          </li>
        )}
      </ul>
    );
  },
  getQueryPath(index, url = window.location.pathname) {
    let splitUrl = url.split("/");

    return splitUrl[index];
  },
  domLoadTimeCard(input) {
    let value = parseFloat(input);

    let bgClass = "bg-primary text-primary";
    let icon = "far fa-check-circle";
    if (value > 0 && value < 2000) {
      bgClass = "bg-success";
      icon = "far fa-check-circle  text-success";
    } else if (value >= 2000 && value < 3000) {
      bgClass = "bg-primary";
      icon = "far fa-check-circle  text-primary";
    } else if (value >= 3000 && value < 4000) {
      bgClass = "bg-warning";
      icon = "fas fa-exclamation-circle text-warning";
    } else if (value >= 4000) {
      bgClass = "bg-danger";
      icon = "fas fa-exclamation-triangle  text-danger";
    }
    return (
      <>
        <i className={icon}></i>&nbsp;{value} (ms)
      </>
    );
  },
  loadTimeCard(input) {
    let value = parseFloat(input);

    let bgClass = "bg-primary";
    let icon = "far fa-check-circle text-primary";
    if (value > 0 && value < 2000) {
      bgClass = "bg-success";
      icon = "far fa-check-circle text-success";
    } else if (value >= 2000 && value < 3000) {
      bgClass = "bg-primary";
      icon = "far fa-check-circle text-primary";
    } else if (value >= 3000 && value < 4000) {
      bgClass = "bg-warning ";
      icon = "fas fa-exclamation-circle text-warning";
    } else if (value >= 4000) {
      bgClass = "bg-danger";
      icon = "fas fa-exclamation-triangle text-danger";
    }
    return (
      <>
        <i className={icon}></i>&nbsp; {value} (ms)
      </>
    );
  },
  validationErrorsCard(input) {
    let value = input;

    let errors = 0;
    if (value) {
      value.map((itm, idx) => {
        if (itm["w3cType"]) {
          if (
            itm["w3cType"].toLowerCase() == "error" ||
            itm["w3cType"].toLowerCase() == "warning"
          ) {
            errors++;
          }
        }
      });

      let bgClass = "bg-primary";
      let icon = "far fa-check-circle text-primary";
      if (errors == 0) {
        bgClass = "bg-success";
        icon = "far fa-check-circle text-success";
      } else if (errors > 0 && errors < 150) {
        bgClass = "bg-warning";
        icon = "fas fa-exclamation-circle text-warning";
      } else if (errors >= 150) {
        bgClass = "bg-danger";
        icon = "fas fa-exclamation-triangle text-danger";
      }
      return (
        <>
          <i className={icon}></i>&nbsp; {errors} needs attention
        </>
      );
    }
    return "";
  },
  isEmpty: (val) => {
    return val === undefined || val == null || val.length <= 0 ? true : false;
  },
  getCurrentRoute: () => {
    const selectedRoute = routes.find((item) => {
      return matchPath(window.location.pathname, {
        path: item.path,
        exact: true,
      });
    });

    const currentRoute = matchPath(window.location.pathname, {
      path: selectedRoute.path,
      exact: true,
      strict: false,
    });

    return { ...currentRoute, name: selectedRoute?.name };
  },
  seoIssuesCard: (input) => {
    let data = input;

    let pageAttributes = 0;
    let ogAttributes = 0;
    let twitterAttributes = 0;

    if (data.defaultPageAttributes) {
      Object.keys(data.defaultPageAttributes).map((itm, idx) => {
        if (
          $f.isEmpty(data.defaultPageAttributes[itm]) &&
          $f.isEmpty(data.pageAttributes[itm])
        ) {
          pageAttributes++;
        }
      });

      Object.keys(data.defaultOgTagsPageAttributes).map((itm, idx) => {
        if (
          $f.isEmpty(data.defaultOgTagsPageAttributes[itm]) &&
          $f.isEmpty(data.ogTagsPageAttributes[itm])
        ) {
          ogAttributes++;
        }
      });

      Object.keys(data.defaultTwitterPageAttributes).map((itm, idx) => {
        if (
          $f.isEmpty(data.defaultTwitterPageAttributes[itm]) &&
          $f.isEmpty(data.twitterPageAttributes[itm])
        ) {
          twitterAttributes++;
        }
      });

      return (
        <React.Fragment>
          <div className="mb-2">
            <label className="form-label me-2">
              Total current basic meta tags missing:{" "}
            </label>
            <span className=" strong">{pageAttributes}</span>
          </div>
          <div className="mb-2">
            <label className="form-label me-2">
              Total current OG meta tags missing:{" "}
            </label>
            <span className=" strong">{ogAttributes}</span>
          </div>
          <div className="mb-2">
            <label className="form-label me-2">
              Total current Twitter meta tags missing:{" "}
            </label>
            <span className=" strong">{twitterAttributes}</span>
          </div>
        </React.Fragment>
      );
    }
    return "";
  },
  ogSummaryCard: (input) => {
    let data = input;

    if (data.ogTagsPageAttributes) {
      let title = !$f.isEmpty(data.ogTagsPageAttributes["ogTitle"])
        ? data.ogTagsPageAttributes["ogTitle"]
        : data.defaultOgTagsPageAttributes["ogTitle"];
      let description = !$f.isEmpty(data.ogTagsPageAttributes["ogDescription"])
        ? data.ogTagsPageAttributes["ogDescription"]
        : data.defaultOgTagsPageAttributes["ogDescription"];

      if ($f.isEmpty(title)) {
        title = (
          <span className="text-danger">
            <i>Title is empty</i>
          </span>
        );
      }

      if ($f.isEmpty(description)) {
        description = (
          <span className="text-danger">
            <i>Description is empty</i>
          </span>
        );
      }

      return (
        <React.Fragment>
          <div className="mb-2">
            <label className="form-label me-2">Title</label>
            <span className="d-block strong">{title}</span>
          </div>
          <div className="mb-2">
            <label className="form-label me-2">Description</label>
            <span className="d-block strong">{description}</span>
          </div>
        </React.Fragment>
      );
    }
    return "";
  },
  twitterSummaryCard: (input) => {
    let data = input;

    if (data.twitterPageAttributes) {
      let title = !$f.isEmpty(data.twitterPageAttributes["twitterTitle"])
        ? data.twitterPageAttributes["twitterTitle"]
        : data.defaultTwitterPageAttributes["twitterTitle"];
      let description = !$f.isEmpty(
        data.twitterPageAttributes["twitterDescription"]
      )
        ? data.twitterPageAttributes["twitterDescription"]
        : data.defaultTwitterPageAttributes["twitterDescription"];

      if ($f.isEmpty(title)) {
        title = (
          <span className="text-danger">
            <i>Title is empty</i>
          </span>
        );
      }

      if ($f.isEmpty(description)) {
        description = (
          <span className="text-danger">
            <i>Description is empty</i>
          </span>
        );
      }

      return (
        <React.Fragment>
          <div className="mb-2">
            <label className="form-label me-2">Title</label>
            <span className="d-block strong">{title}</span>
          </div>
          <div className="mb-2">
            <label className="form-label me-2">Description</label>
            <span className="d-block strong">{description}</span>
          </div>
        </React.Fragment>
      );
    }
    return "";
  },
  basicSummaryCard: (input) => {
    let data = input;

    if (data) {
      let title = !$f.isEmpty(data.pageAttributes["metaTitle"])
        ? data.pageAttributes["metaTitle"]
        : data.defaultPageAttributes["metaTitle"];
      let description = !$f.isEmpty(data.pageAttributes["metaDescription"])
        ? data.pageAttributes["metaDescription"]
        : data.defaultPageAttributes["metaDescription"];

      if ($f.isEmpty(title)) {
        title = (
          <span className="text-danger">
            <i>Title is empty</i>
          </span>
        );
      }

      if ($f.isEmpty(description)) {
        description = (
          <span className="text-danger">
            <i>Description is empty</i>
          </span>
        );
      }

      return (
        <React.Fragment>
          <div className="mb-2">
            <label className="form-label me-2">Title</label>
            <span className="d-block strong">{title}</span>
          </div>
          <div className="mb-2">
            <label className="form-label me-2">Description</label>
            <span className="d-block strong">{description}</span>
          </div>
        </React.Fragment>
      );
    }
    return "";
  },
  checkQueryParam(name) {
    const url = new URL(window.location.href);
    return url.searchParams.has(name);
  },
  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  },
};

export default $f;
