import React, { useState } from "react";
import MethodSelection from "./MethodSelection";
import { useHistory, useParams } from "react-router-dom";
import { fetchSite } from "../../apis/sites";
import { useMutation, useQuery } from "react-query";
import LoadingOverlayScreen from "../../components/LoadingOverlayScreen";
import FailedToAccessImportPages from "./FailedToAccessImportPages";
import { calculatePrice } from "../../apis/billing";
import FinalizeImport from "./FinalizeImport";
import $f from "../../components/lib";

export default function ImportPages() {
  const [status, setStatus] = useState(undefined);
  const [finalizedData, setFinalizedData] = useState(undefined);
  const history = useHistory();
  const { siteId } = useParams();

  const { mutate: calculate, status: calculateStatus } = useMutation(
    calculatePrice,
    {
      throwOnError: true,
      onError: (err) => {
        if (["NO_VALID_URL", "DUPLICATE_URL"].includes(err.ERROR_TAG)) {
          $f.createNotification({
            message:
              "One or more urls you entered don't meet the format requirements",
            type: "danger",
          });
        } else {
          $f.createNotification({
            message: "Something went wrong.Please try again later.",
            type: "danger",
          });
        }
      },
      onSuccess: (res) => {
        setFinalizedData(res);
        setStatus("FINALIZED");
      },
    }
  );

  const {
    data: site,
    isLoading,
    refetch,
  } = useQuery(["site", { siteId }], fetchSite, {
    onSuccess: (res) => {
      if (res.verified) {
        setStatus("VERIFIED");
      } else {
        setStatus("UNVERIFIED");
      }
    },
    onError: (err) => {
      if (err.ERROR_TAG === "NOT_FOUND") {
        setStatus("NOT_FOUND");
      } else {
        setStatus("GENERAL_ERROR");
      }
    },
  });

  if (isLoading) {
    return <LoadingOverlayScreen />;
  }

  const hasMethodQuery = $f.checkQueryParam("method");
  const method = $f.getParameterByName("method");

  const breadcrumb = () => {
    if (hasMethodQuery) {
      if (method === "IMPORT_SITEMAP") {
        return (
          <div>
            <span>Select method</span>
            <span>
              <img src="/images/arrow.png" className="mx-2" height="9px" />
              <span
                className={
                  status !== "FINALIZED" ? "border-bottom border-primary" : ""
                }
              >
                Import sitemap
              </span>
            </span>
            {status === "FINALIZED" && (
              <span>
                <img src="/images/arrow.png" className="mx-2" height="9px" />
                <span className={"border-bottom border-primary"}>
                  Finalize import
                </span>
              </span>
            )}
          </div>
        );
      }

      if (method === "COPY_PASTE") {
        return (
          <div>
            <span>Select method</span>
            <span>
              <img src="/images/arrow.png" className="mx-2" height="9px" />
              <span
                className={
                  status !== "FINALIZED" ? "border-bottom border-primary" : ""
                }
              >
                Copy and paste
              </span>
            </span>
            {status === "FINALIZED" && (
              <span>
                <img src="/images/arrow.png" className="mx-2" height="9px" />
                <span className="border-bottom border-primary">
                  Finalize import
                </span>
              </span>
            )}
          </div>
        );
      }

      if (method === "UPLOAD_FILE") {
        return (
          <div>
            <span>Select method</span>
            <span>
              <img src="/images/arrow.png" className="mx-2" height="9px" />
              <span
                className={
                  status !== "FINALIZED" ? "border-bottom border-primary" : ""
                }
              >
                Upload file
              </span>
            </span>
            {status === "FINALIZED" && (
              <span>
                <img src="/images/arrow.png" className="mx-2" height="9px" />
                <span className="border-bottom border-primary">
                  Finalize import
                </span>
              </span>
            )}
          </div>
        );
      }
    } else {
      return (
        <span className="border-bottom border-primary">Select method</span>
      );
    }
  };

  const getViewport = () => {
    if (status === "VERIFIED" || status === "METHOD_SELECTION") {
      return <MethodSelection priceCalculation={calculate} site={site} />;
    }

    if (status === "UNVERIFIED") {
      return <FailedToAccessImportPages type="UNVERIFIED" refetch={refetch} />;
    }

    if (status === "NOT_FOUND") {
      return <FailedToAccessImportPages type="NOT_FOUND" />;
    }

    if (status === "GENERAL_ERROR") {
      return <FailedToAccessImportPages type="GENERAL_ERROR" />;
    }

    if (status === "FINALIZED") {
      return (
        <FinalizeImport
          setStatus={setStatus}
          finalizedData={finalizedData}
          siteName={site?.name ?? "-"}
        />
      );
    }
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <LoadingOverlayScreen
        label="The price calculation is in progress.This might take a while..."
        active={calculateStatus === "loading"}
      />
      <header>
        <nav
          className="navbar navbar-expand p-2 pe-4 ps-md-4 border-bottom-1 border bg-white "
          draggable="false"
        >
          <div className="viewport-padding">
            <div className="d-flex flex-row ">
              <span className="mb-0">
                <img src="/images/purple.png" height="44px" />
              </span>
              <div className="d-flex flex-column ms-4">
                <div>
                  <b>Import pages to {site?.name ?? "-"}</b>
                </div>
                <div>{breadcrumb()}</div>
              </div>
            </div>
          </div>
          <div className="nav-item ms-auto ">
            <button
              type="button"
              className="btn hoverable-lightgray text-grayblack border bg-white btn-sm button-custom"
              onClick={() => {
                history.goBack();
              }}
            >
              Exit
            </button>
          </div>
        </nav>
      </header>
      {getViewport()}
    </div>
  );
}
